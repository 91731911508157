import image1 from '../assests/sectors/1.webp';
import image2 from '../assests/sectors/2.webp';
import image3 from '../assests/sectors/3.webp';
import image4 from '../assests/sectors/4.webp';
import image5 from '../assests/sectors/5.webp';
import image6 from '../assests/sectors/6.webp';
import image7 from '../assests/sectors/7.webp';
import image8 from '../assests/sectors/8.webp';
import image9 from '../assests/sectors/9.webp';
import image10 from '../assests/sectors/10.webp';
import image11 from '../assests/sectors/11.webp';
import image12 from '../assests/sectors/12.webp';
import image13 from '../assests/sectors/13.webp';
import image14 from '../assests/sectors/14.webp';
import image15 from '../assests/sectors/15.webp';
import image16 from '../assests/sectors/16.webp';
import image17 from '../assests/sectors/17.webp';
import image18 from '../assests/sectors/18.webp';
import image19 from '../assests/sectors/19.webp';
import image20 from '../assests/sectors/20.webp';
import image21 from '../assests/sectors/21.webp';
import image22 from '../assests/sectors/22.webp';
import image23 from '../assests/sectors/23.webp';
import image24 from '../assests/sectors/24.webp';
import image25 from '../assests/sectors/25.webp';
import image26 from '../assests/sectors/26.webp';



const domainData = [
  { id: 1, image: image1, name: 'Railways' },
  { id: 2, image: image3, name: 'I.T Industries' },
  { id: 3, image: image2, name: 'Airports' },
 
  { id: 4, image: image4, name: 'Sugar Industries' },

  { id: 5, image: image6, name: 'Hydro Electric Power Station' },
  { id: 6, image: image16, name: 'Mine industries' },

  { id: 7, image: image7, name: 'Lift Irrigation Scheme' },
  { id: 8, image: image8, name: 'Water Treatment Plant' },
  { id: 9, image: image13, name: 'Textile & Garment Sector' },

  // { id: , image: image9, name: 'Municipal Corporation- Pune/Pimpri/Navi Mumbai' },
  { id: 10, image: image15, name: 'Chemical & Pharmaceuticals Sector' },
  { id: 11, image: image14, name: 'Steel & other Metallurgical Sector' },
  { id: 12, image: image17, name: 'Food & Beverages Sector' },
  { id: 13, image: image12, name: 'Automobile Sector' },

  { id: 14, image: image11, name: 'Generation & Transmission Sector' },
  { id: 15, image: image26, name: 'Commercial complex, shopping mall' },
  { id: 16, image: image24, name: 'Solar power' },
  { id: 17, image: image25, name: 'Real estate' },
  { id: 18, image: image20, name: '  Cement Industry and other industries etc.' },

  { id: 19, image: image18, name: 'Hospitality & Hospital Sector' },
  { id: 20, image: image19, name: ' Educational Institutes' },
  { id: 21, image: image23, name: 'Wind Power' },

  { id: 22, image: image21, name: ' Oil & GAS Petroleum industry' },
  { id: 23, image: image22, name: 'Defence sector, MES/NDA/DRDO' },
  
];

export default domainData;
