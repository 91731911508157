

import React from 'react';
import Header from '../Components/Header/Header.js';
import BusDuct from '../Components/Products/BusDuct/BusDuct.js';
import Footer from '../Components/Footer/Footer.js'

function BusDuctPage() {
  return (
    <div className="">
      <Header/> 
    <BusDuct/>
     <Footer/>
    </div>
  );
}

export default BusDuctPage;
