import SpareImage from '../p-assestes/spareservice.webp';

const spareServiceData = [
  {
    id: 1,
    image: SpareImage,
    description: "Spare & services",
    info:"HTS has highly qualified experts and  service engineers who undertake all  VCB switchgear panel overhauls and  servicing up to 33 KV, and yearly  maintenance contracts for many  different industries. We maintain  adequate spares of critical  components for emergencies and  can supply spares for ABB breakers  at very competitive rates."
  }
];

export default spareServiceData;
