import frame1 from '../w-assests/team-1.webp';
import frame2 from '../w-assests/team-2.webp';
import frame3 from '../w-assests/team-3.webp';


export const profiles = [
  {
    name: "Mr. Sukamar Badave",
    title: "(Operations Director)",
    image: frame1, 
    description: "Over 31 Years of executive leadership and management experience in manufacturing operations. Strong portfolio of achievements that includes cost reductions, 180-degree turnarounds for underperforming operations, fast track growth for start-up enterprises and significant quality, safety, productivity, and efficiency improvements."
  },
  {
    name: "Mr. P. L. Dixit",
    title: "(Sales Director)",
    image: frame2, 
    description: "Highly motivated and experienced sales icon with 34+ years of proven history of business development, success in domestic and international sales, services, marketing and channel management. Special expertise in building and leading top-performing teams, coordinating critical activities. Experienced at directing sales to new heights and exploring new markets for company’s next level growth."
  },
  {
    name: "Mr. R.B. Khopkar",
    title: "(Technical Director)",
    image: frame3, 
    description: "Industry-known Technocrat with 30 years of vast experience in related field like design, engineering, new product development. He Promotes and coordinates technical knowledge harvesting within the organization. Defines, communicates and enforces industry standards. He is Visionary of positive results in driving internal growth with futuristic approach. His thrust on ensuring the technical quality of the various deliverables of the project while understanding the political and strategic aspects are contributing to staff development and contributing to the leadership."
  }
];
