import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SlArrowRightCircle } from "react-icons/sl";
import AOS from 'aos';
import 'aos/dist/aos.css';

import './SectorWework.css';
import bannerImg from '../H-asset/sectorwework .svg';
import railwaySVG from '../H-asset/1.webp';
import airportPNG from '../H-asset/2.webp';
import sugarPNG from '../H-asset/3.webp';
import itPNG from '../H-asset/4.webp';

import viewnext from '../H-asset/viewnext.svg';

const SectorWeWorkMain = () => {
  const navigate = useNavigate();
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    AOS.init({
      once: true,
      offset: 50,
      duration: 1000,
    });

    setTimeout(() => {
      setShowButton(true);
    }, 3000);
  }, []);

  const handleNavigation = () => {
    navigate('/sector-we-work');
  };

  return (
    <div className="domainMain-container domainMainsub">
      <div className="domainMain-banner">
        <img src={bannerImg} alt="Banner" className="domainMain-bannerImg" data-aos="fade-up" data-aos-delay="200" />
      </div>
      <div className="domainMain-grid">
        <div className="domainMain-gridItem" data-aos="fade-up" data-aos-delay="500">
          <img src={railwaySVG} alt="Railways" className="domainMain-img" />
          <div className="domainMain-info">Railways</div>
        </div>
        <div className="domainMain-gridItem" data-aos="fade-up" data-aos-delay="1000">
          <img src={airportPNG} alt="Airports" className="domainMain-img" />
          <div className="domainMain-info">Airports</div>
        </div>
        <div className="domainMain-gridItem" data-aos="fade-up" data-aos-delay="1500">
          <img src={itPNG} alt="IT Industries" className="domainMain-img" />
          <div className="domainMain-info">Sugar Industries</div>
        </div>
        <div className="domainMain-gridItem" data-aos="fade-up" data-aos-delay="2000">
          <img src={sugarPNG} alt="Sugar Industries" className="domainMain-img" />
          <div className="domainMain-info">IT Industries</div>
        </div>
       
      </div>
      {showButton && (
        <div className="view-all-button-container">
          <button className="view-all-button vab-1" onClick={handleNavigation}>
            View All 
            <SlArrowRightCircle className="view-all-icon" />
          </button>
        </div>
      )}
    </div>
  );
};

export default SectorWeWorkMain;
