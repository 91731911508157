import React from 'react';
import Header from '../Components/Header/Header';
import CarouselComponent from '../Components/Home/carouselComp/carouselComp';
import Unit1 from '../Components/Home/Unit-1/Unit1';
import WhyHts from '../Components/Home/WhyHts/WhyHts';
import WhyChooseUs from '../Components/Home/WhyChooseUs/WhyChooseUs';
import SectorWework from '../Components/Home/SectorWework/SectorWework';
import OurClients from '../Components/Home/OurClients/OurClients';
import Footer from '../Components/Footer/Footer.js';
import './Home.css';
function Home() {
  return (
    <div className="Home">
      <Header /> 
      <div className="content">
        <CarouselComponent /> 
        <Unit1 />
        <WhyHts />
        <WhyChooseUs />
        <SectorWework />
        {/* <OurClients /> */}
        {/* <Slider /> */}
        <Footer />
      </div>
    </div>
  );
}

export default Home;
