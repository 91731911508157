

import React from 'react';
import Header from '../Components/Header/Header.js';

import Footer from '../Components/Footer/Footer.js'
import LvSolution from '../Components/Products/LvSolution/LvSolution.js';

function LvSolutionPage() {
  return (
    <div className="">
      <Header/> 
     <LvSolution/>
     <Footer/>
    </div>
  );
}

export default LvSolutionPage;
