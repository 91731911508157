import React, { useState, useEffect } from 'react';
import './ContactUs1Comp.css';
import bannerImage from './contact-assests/banner.webp';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

const ContactUs1Comp = () => {
  const [formData, setFormData] = useState({
    name: '',
    companyname: '',
    phonenumber: '',
    emailid: '',
    message: ''
  });
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false 
    });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    // Validate field in real-time if needed
    validateField(name, value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm(formData);
    if (Object.keys(formErrors).length === 0) {
      try {
        // Send form data to backend
         await axios.post('https://hts-three.vercel.app/api/v1/CreateContactDetails', formData);
        // Reset form data and show success message
        setFormData({
          name: '',
          companyname: '',
          phonenumber: '',
          emailid: '',
          message: ''
        });
        setErrors({});
        setSuccess(true);
        // Show success toast
        toast.success("Thank you! We'll be in touch soon.!");
      } catch (error) {
        console.error('Error submitting form:', error);
        toast.error('Error submitting form. Please try again later.');
      }
    } else {
      setErrors(formErrors);
      // Show validation error toast
      toast.error('Please fill in all required fields.');
    }
  };

  const validateForm = (data) => {
    let formErrors = {};
    // Validate each field
    if (!data.name.trim()) {
      formErrors.name = 'Name is required';
    }
    if (!data.emailid.trim()) {
      formErrors.emailid = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(data.emailid)) {
      formErrors.emailid = 'Invalid email address';
    }
    if (!data.message.trim()) {
      formErrors.message = 'Message is required';
    }
    return formErrors;
  };

  const validateField = (fieldName, value) => {
    let fieldErrors = {};
    switch (fieldName) {
      case 'emailid':
        if (!/\S+@\S+\.\S+/.test(value)) {
          fieldErrors.emailid = 'Invalid email address';
        }
        break;
      // Add more field-specific validation if needed
      default:
        break;
    }
    setErrors({ ...errors, [fieldName]: fieldErrors[fieldName] });
  };

  const scrollToContactForm = () => {
    // Scroll to the contact form section
    const contactFormSection = document.getElementById('contact-form-section');
    contactFormSection.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="contact-us-container">
      <img
        src={bannerImage}
        alt="Contact Us Banner"
        className="banner-image"
        onClick={scrollToContactForm}
      />

      <div id="contact-form-section" className="contact-sections">
        {/* Left Section */}
        <div className="contact-info-section" data-aos="fade-up">
          <h2 className="contact-info-heading" data-aos-delay="200">Contact Us:</h2>
          <div className='contact-info-text-main'>
            <p className="contact-info-text" data-aos="fade-up" data-aos-delay="300">
              Connect with us today to electrify your solutions and spark innovation.
              Our team is ready to amplify your success – reach out and let's power up together.
            </p>
          </div>
        </div>

        {/* Right Section */}
        <div className="contact-input-section" data-aos="fade-up" data-aos-delay="400">
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Your Name"
              className={`contact-input ${errors.name && 'input-error'}`}
              value={formData.name}
              onChange={handleChange}
            />

            <input
              type="text"
              name="companyname"
              placeholder="Your Company Name"
              className="contact-input"
              value={formData.companyname}
              onChange={handleChange}
            />

            <input
              type="tel"
              name="phonenumber"
              placeholder="Phone Number"
              className="contact-input"
              value={formData.phonenumber}
              onChange={handleChange}
            />

            <input
              type="email"
              name="emailid"
              placeholder="Email-id"
              className={`contact-input ${errors.emailid && 'input-error'}`}
              value={formData.emailid}
              onChange={handleChange}
            />

            <textarea
              name="message"
              placeholder="Your Message"
              className={`contact-textarea ${errors.message && 'input-error'}`}
              value={formData.message}
              onChange={handleChange}
            ></textarea>

            <button type="submit" className="contact-submit">Submit</button>

            <ToastContainer />
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs1Comp;
