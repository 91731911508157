import productImage1 from '../p-assestes/lvsolution/1.webp';
import productImage2 from '../p-assestes/lvsolution/2.webp';
import productImage3 from '../p-assestes/lvsolution/3.webp';
import productImage4 from '../p-assestes/lvsolution/4.webp';
import productImage5 from '../p-assestes/lvsolution/5.webp';
import productImage6 from '../p-assestes/lvsolution/6.webp';

// import productImage7 from '../p-assestes/lvsolution/7.png';
// import productImage8 from '../p-assestes/lvsolution/8.png';
// import productImage9 from '../p-assestes/lvsolution/9.png';
// import productImage10 from '../p-assestes/lvsolution/10.png';



const productsData = [
  {
    id: 1,
    image: productImage1,
    description: "Fixed type LT switchgears & instrumentation Panel",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac ante eu elit pellentesque consequat. Fusce eget est nec nisi mattis ullamcorper. Sed efficitur odio eget libero convallis, at ultrices nulla ultricies. Phasellus tristique, sapien id tincidunt sollicitudin, lectus ligula tincidunt orci, eu vestibulum libero quam ut ex."
  },
  {
    id: 2,
    image: productImage2,
    description: "Automatic Power Factor Correction Panels [APFC] for HT & LT",
    info: "Donec posuere massa id posuere volutpat. Morbi lobortis bibendum diam, a ultricies justo tincidunt eu. In hac habitasse platea dictumst. Maecenas id nulla nec ipsum egestas mattis. Integer sollicitudin feugiat neque, ac semper ligula facilisis non."
  },
  {
    id: 3,
    image: productImage3,
    description: "LT PCC/MCC panel",
    info: "Quisque vitae lorem quis orci facilisis vestibulum. Nam nec turpis eu arcu vehicula volutpat. Duis non sapien vitae ipsum ullamcorper malesuada nec eu eros. Vivamus laoreet metus in diam consectetur, nec dapibus nunc sodales. Nunc non neque id est facilisis lacinia. Vivamus gravida dictum turpis."
  },
  {
    id: 4,
    image: productImage4,
    description: "ATS Control Panel",
    info: "Curabitur vel enim risus. Sed tincidunt est eget magna tincidunt, nec volutpat turpis pellentesque. In vitae massa at leo tempus posuere. Aenean sed nisi odio. Vivamus accumsan libero ac mi varius congue. Nullam fringilla lacinia lectus, sed gravida est varius ac."
  },
  {
    id: 5,
    image: productImage5,
    description: "Auto Main Failure (AMF) Panels",
    info: "Suspendisse potenti. Aliquam ac lacinia risus. Fusce aliquet cursus orci, id dictum justo venenatis eget. Phasellus consequat nulla vel velit tristique, nec eleifend ligula ultricies. Proin consequat sollicitudin est, eu mollis nisl viverra at."
  },
  {
    id: 6,
    image: productImage6,
    description: " Distribution board",
    info: "A distribution board, which is fed from the  transformer, distributes the power to  numerous individual circuits, or DBs. A  wide range of compact, elegant, and  economical DBs with unique features,  designed and engineered to provide user  safety, convenience, and operational /  maintenance advantages are offered. "
  }
];

export default productsData;
