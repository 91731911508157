import React, { useState, useEffect } from 'react';
import './MvSolution.css';
import searchIcon from '../p-assestes/mynaui_search.png';
import productbanner from '../p-assestes/mvbanner.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import productsData from './productsData';
import hydroCoGenData from './hydroCoGenData';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

const MvSolution = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [aosEnabled, setAosEnabled] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 768) {
      setAosEnabled(true);
      AOS.init({
        duration: 1000,
        easing: 'ease-in-out', 
        once: false ,
      });
    }
  }, []);
  

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredProducts = productsData.filter(product =>
    product.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredHydroCoGenProducts = hydroCoGenData.filter(product =>
    product.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="mv-solution-wrapper margin-top">
      <div className="search-bar">
        <img src={searchIcon} alt="Search Icon" className="search-icon" />
        <input 
          type="text" 
          placeholder="Search For Product" 
          className="search-input"
          value={searchTerm} 
          onChange={handleSearchChange}
        />
      </div>

      <div className="product-title">Product <FontAwesomeIcon icon={faAngleRight} className="arrow-icon" /> MV Solution <span>(total {filteredProducts.length} Products)</span></div>

      <div className="product-section">
        <img src={productbanner} alt="Product" className="product-image" />
      </div>
      
      <div className="product-list">
        {filteredProducts.map((product, index) => (
          <div className="product-box" key={product.id} data-aos={aosEnabled ? "fade-up" : ""} data-aos-delay={aosEnabled ? 100 * index : ""}>
            <img src={product.image} alt="Product" className="product-box-image" />
            <div className="product-description">{product.description}</div>
            <Link to={`/mv-product/product/${product.id}`} className="explore-link">
              <button className="explore-button">
                Explore 
                <FontAwesomeIcon icon={faAngleRight} className="button-icon" />
              </button>
            </Link>
          </div>
        ))}
      </div>

      <div className="product-section">
        <div className="product-title">Panels Required for Hydro / Co-Generation <FontAwesomeIcon icon={faAngleRight} className="arrow-icon" /></div>
        <div className="product-list">
          {filteredHydroCoGenProducts.map((product, index) => (
            <div className="product-box" key={product.id} data-aos={aosEnabled ? "fade-up" : ""} data-aos-delay={aosEnabled ? 100 * index : ""}>
              <img src={product.image} alt="Product" className="product-box-image" />
              <div className="product-description">{product.description}</div>
              <Link to={`/mv-product/hydro/${product.id}`} className="explore-link">
                <button className="explore-button">
                  Explore 
                  <FontAwesomeIcon icon={faAngleRight} className="button-icon" />
                </button>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MvSolution;
