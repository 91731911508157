import checkIcon from '../w-assests/Group.svg'; 

const checkImage = <img src={checkIcon} alt="check" className="check-icon" />;

export const points = [
  {
    icon: checkImage,
    text: 'Complying with global quality standards.'
  },
  {
    icon: checkImage,
    text: 'Complete Solution providers in Electrical Power, Control and Automation requirements.'
  },
  {
    icon: checkImage,
    text: 'Well-built manufacturing plant.'
  },
  {
    icon: checkImage,
    text: 'CNC aided manufacturing process with aesthetically appealing finish.'
  },
  {
    icon: checkImage,
    text: 'Prompt delivery of products.'
  },
  {
    icon: checkImage,
    text: 'Cost-effective.'
  },
  {
    icon: checkImage,
    text: 'An innovative manufacturing facilities arrangement.'
  },
  {
    icon: checkImage,
    text: 'We have been accredited to ISO 9001:2015, and all of our panel designs are Type tested.'
  },
  {
    icon: checkImage,
    text: 'Our goods are made in accordance with customer specifications.'
  },
  {
    icon: checkImage,
    text: 'Our staff of highly skilled technicians provides exceptional after-sales services.'
  },
  {
    icon: checkImage,
    text: 'Our Panels are designed to make bulk production on CNC machines easier, which has improved overall aesthetics and made installation at sites easier. This has increased our capacity to supply multi-panel switchboards in a very short amount of time.'
  },
  {
    icon: checkImage,
    text: 'Excellent opportunities in project-focused packaged systems.'
  },
  {
    icon: checkImage,
    text: 'Vast experience in handling Projects from Design, Engineering, Supply and commissioning of all electrical and automation systems globally.'
  },
  {
    icon: checkImage,
    text: 'Client / Consultant / EPC contractors Favorites due to timely supply of product with quantity and after sales services.'
  },
  {
    icon: checkImage,
    text: 'Operating in ERP environment to monitor, control and deliver the systems on time.'
  }
];
