import React, { useEffect } from 'react';
import './Contact3Comp.css';
import 'animate.css';

import { FiMail, FiPhone } from 'react-icons/fi';
import PhIcon from './contact-assests/phicon.svg';
import EmailIcon from './contact-assests/email.svg';
import socialIcon1 from './contact-assests/social-icon/logos_facebook.svg';
import socialIcon2 from './contact-assests/social-icon/skill-icons_instagram.svg';
import socialIcon3 from './contact-assests/social-icon/Group.svg';
import socialIcon4 from './contact-assests/social-icon/skill-icons_twitter.svg';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Contact3Comp = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            easing: 'ease-in-out',
            once: false // Allow animations to repeat
        });
    }, []);

    return (
        <div className="contact3-container">
           <div className="contact3-section" data-aos="fade-up">
      <img className="contact3-icon contact3-phone-icon" src={EmailIcon} alt="Email Icon" />
      <div>
        <a href='mailto:marketing@htswitchgears.com'><span className="contact3-email">marketing@htswitchgears.com</span></a>
        <br />
        <a href='mailto:sales@htswitchgears.com'><span className="contact3-email">sales@htswitchgears.com</span></a>
      </div>
    </div>
            <div className="contact3-section center-section" data-aos="fade-up" data-aos-delay="200">
    <img className="contact3-phone contact3-phone-icon" src={PhIcon} alt="Phone Icon" />
    <a href="tel:+91-020-2529-3812" className=''>+(91) 020-2529 3812/13/14</a>
</div>


<div className="contact3-section follow-us" data-aos="fade-up" data-aos-delay="300">
      <span className="follow-text">Follow Us:</span>
      <img src={socialIcon1} alt="Social Icon 1" className="social-icon contact3-phone-icon" />
      <img src={socialIcon2} alt="Social Icon 2" className="social-icon contact3-phone-icon" />
      <img src={socialIcon3} alt="Social Icon 3" className="social-icon contact3-phone-icon" />
      <img src={socialIcon4} alt="Social Icon 4" className="social-icon contact3-phone-icon" />
    </div>
        </div>
    );
};

export default Contact3Comp;
