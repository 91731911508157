import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FiMail, FiPhone } from 'react-icons/fi'; // Import email and phone icons
import './Footer.css';
import logo from '../assests/image 1.svg';
import followUsIcon1 from '../assests/social-icon/Group.svg';
import followUsIcon2 from '../assests/social-icon/logos_facebook.svg';
import followUsIcon3 from '../assests/social-icon/skill-icons_instagram.svg';
import followUsIcon4 from '../assests/social-icon/skill-icons_twitter.svg';
import mailimg from '../assests/material-symbols_mail.png';
import phimg from '../assests/Vector.png';
const Footer = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      easing: 'ease-in-out', // Easing for smoother animations
      delay: 500, // Delay before animation starts
      once: false,
      disable: window.innerWidth <= 768, 
    });
  }, []);

  return (
    <div className="footer-container-custom">
      <div className="logo-section-custom" >
        <img src={logo} alt="HT Switchgears Logo" className="center-logo-custom" />
      </div>
      <div className="links-section-custom" data-aos="fade-up" data-aos-delay="100">
        <div className="top-links-custom  ">
          <span>
            <a href="/" className="footer-link">Home</a>
            <a href="/about-us" className="footer-link">About Us</a>
            <a href="/mv-solutions" className="footer-link">Products</a>
            <a href="/awards" className="footer-link">Award</a>
            <a href="/clients" className="footer-link">Clients</a>
          </span>
        </div>
        <div className="contact-info-custom">
          <div className="contact-item-custom">
            {/* <FiMail className="contact-icon" /> */}
            <img src={mailimg} className='contact-icon' />
            <div className="contact-text">
              <a href="mailto:marketing@htswitchgears.com" className="email-link-custom">marketing@htswitchgears.com</a>
              <a href="mailto:sales@htswitchgears.com" className="email-link-custom">sales@htswitchgears.com</a>
            </div>
          </div>
          <div className="phone-item-custom">
            {/* <FiPhone className="phone-icon" /> */}

            <img src={phimg} className='phone-icon' />
            <a href="tel:+9102025293812" className='ph-no'>+(91) 020-2529 3812/13/14</a>
          </div>
          <div className='follow-us-custom'>Follow us:</div>
          <div className="follow-us-icons-custom">
            <img src={followUsIcon1} alt="Follow Us Icon" />
            <img src={followUsIcon2} alt="Follow Us Icon" />
            <img src={followUsIcon3} alt="Follow Us Icon" />
            <img src={followUsIcon4} alt="Follow Us Icon" />
          </div>
        </div>
      </div>
      <div className="address-section-custom" data-aos="fade-up" data-aos-delay="200">
        <div className='unitadd-1-custom'>
          <div className='addressunit'>
            Unit I:<br />
            Survey No. 77/5, Dangat Estate, NDA Road,<br />
            A/P - Shivane, Tal. Haveli Dist., Pune - 411023, Maharashtra, INDIA.<br />
            Plant & Office Area : 45000 sq.ft
          </div>
        </div>
        <div className='unitadd-2-custom'>
          <div className='addressunit'>
            Unit II:<br />
            Gate No. 136, A/P, Varve Bk., Tal. Bhor,<br />
            Pune – 412205, Maharashtra, INDIA.<br />
            Plant & Office Area : 68000 sq.ft
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
