// Home.js

import React from 'react';
import Header from '../Components/Header/Header.js';

import Footer from '../Components/Footer/Footer.js'

import Whowe1Comp from '../Components/WhoWeAre/WhoweComp/Whowe1Comp.js';
import Whowe2Comp from '../Components/WhoWeAre/WhoweComp/Whowe2Comp.js';
import Whowe3Comp from '../Components/WhoWeAre/WhoweComp/Whowe3Comp.jsx';
import Whowe4Comp from '../Components/WhoWeAre/WhoweComp/Whowe4Comp.js';


function WhowearePage() {
  return (
    <div className="">
      <Header/> 
     <Whowe1Comp/>
     <Whowe2Comp/>
     <Whowe3Comp/>
     <Whowe4Comp/>
     <Footer/>
    </div>
  );
}

export default WhowearePage;
