import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import './SectorWeWorkMain.css';
import bannerImg from '../assests/sectors/sector we work banner.png';
import domainData from './domainData';

const SectorWeWorkMain = () => {
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      easing: 'ease-in-out', // Easing function
      delay: 500, // Delay between each item animation
    });
  }, []);

  const handleNavigation = () => {
    navigate('/sector-we-work');
  };

  return (
    <div>
      <Header />
      <div className="domainMain-container" data-aos="fade-up">
        <div className="domainMain-banner">
          <img src={bannerImg} alt="Banner" className="domainMain-bannerImg" />
        </div>
        <div className="domainMain-grid">
          {domainData.map((domain, index) => (
            <div
              key={domain.id}
              className="domainMain-gridItem"
              data-aos="fade-up"
              data-aos-delay={index * 200} // Increase delay for each item
            >
              <img src={domain.image} alt={domain.name} className="domainMain-img" />
              <div className="domainMain-info">
                {domain.name}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SectorWeWorkMain;
