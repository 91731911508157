import hydroImage1 from '../p-assestes/hydro/1.webp';
import hydroImage2 from '../p-assestes/hydro/2.webp';
import hydroImage3 from '../p-assestes/hydro/3.webp';
import hydroImage4 from '../p-assestes/hydro/4.webp';
import hydroImage5 from '../p-assestes/hydro/5.webp';
import hydroImage6 from '../p-assestes/hydro/6.webp';
import hydroImage7 from '../p-assestes/hydro/7.webp';


const hydroCoGenData = [
  {
    id: 1,
    image: hydroImage1,
    description: "Generator relay & metering panel",
    info:"These panels are widely used in power  plants and are completely tailor-made  as per customer requirements and  technical specifications. It is  compatible with Scada and remote  operation."
  },
  {
    id: 2,
    image: hydroImage2,
    description: "Transformer relay & metering panel",
    info:"These panels are commonly used in  power plants and are totally customized  to meet the needs of the customer and  technical specifications. It is compatible  with Scada and remote operation."
  },
  {
    id: 3,
    image: hydroImage3,
    description: "Line relay & metering panel",
    info:"These panels are widely used in power  plants and are completely tailor-made  as per customer requirements and  technical specifications. It is compatible  with Scada and remote operation."
  },
  {
    id: 4,
    image: hydroImage4,
    description: "Synchronizing panel",
    info:"Synchronizing panels are primarily  utilized to suit the requirement of power  system. For one or more generator  breakers, these panels offer both  manual and automatic synchronization.  They're frequently used to synchronize  generators and provide multiplexing  options."
  },
  {
    id: 5,
    image: hydroImage5,
    description: "LAVT panel",
    info:"An LAVT panel is a cubicle that  houses a lightning Arrester (surge  Arrester), surge capacitor, and  voltage or potential transformers for  a phase-isolated bus duct. LAVT  panels are used in all high-voltage  power plants for the protection of  the alternator."
  },
  {
    id: 6,
    image: hydroImage6,
    description: "NGT panel",
    info:"The NGT Panel is used for the  resistance grounding of power  systems. It is used when the neutral of  the supply transformer is available  and its own impedance is not enough  to limit the fault current. Our NGT/NGR  panels are made with our own single  pole. A manual or motorized isolator  and a single-pole vacuum contactor  are also provided as per the  customer's requirements"
  },
  {
    id: 7,
    image: hydroImage7,
    description: "UCB",
    info:"UCB provides an interface for operators  to monitor and control the specific  generating  unit. UCB in a hydroelectric  project is essential for the safe, reliable,  and efficient operation of individual  generating units within the power plant. It ensures optimal electricity generation."
  }
];

export default hydroCoGenData;
