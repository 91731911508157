import React from 'react';
import { Carousel, Button } from 'react-bootstrap';
import { SlArrowRightCircle } from "react-icons/sl";

import './carouselComp.css';
import slide1 from '../../assests/c-1.webp';
import slide2 from '../../assests/c-2.webp';
import slide3 from '../../assests/c-3.webp';
import slide4 from '../../assests/c-4.webp';


const CarouselComponent = () => {
  return (
    <Carousel className="carousel-container " interval={3000} >
      <Carousel.Item>
        
          <img
            className="d-block w-100 main-carousel-image img-fluid"
            src={slide1}
            alt="First slide"
          />
        
        <Carousel.Caption className="carousel-caption">
        <Button href="/mv-solutions" variant="primary" className="learn-more-button">Explore More  <SlArrowRightCircle className="learn-more-icon" /></Button> 

      
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        
          <img
            className="d-block w-100 main-carousel-image img-fluid"
            src={slide2}
            alt="Second slide"
          />
    
        <Carousel.Caption className="carousel-caption">
         <Button href="/lv-solutions" variant="primary" className="learn-more-button" >Explore More <SlArrowRightCircle className="learn-more-icon" /></Button> 
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        
          <img
            className="d-block w-100 main-carousel-image img-fluid"
            src={slide3}
            alt="Third slide"
          />
      
        <Carousel.Caption className="carousel-caption">
          {/* <Button href="/page3" variant="primary" className="learn-more-button" style={{ top: '50%', right: '5%' }}>Learn More</Button> */}
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
       
          <img
            className="d-block w-100 main-carousel-image img-fluid"
            src={slide4}
            alt="Fourth slide"
          />
       
        <Carousel.Caption className="carousel-caption">
          {/* <Button href="/page4" variant="primary" className="learn-more-button" style={{ top: '80%', left: '10%' }}>Learn More</Button> */}
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default CarouselComponent;
