import lvimage1 from '../p-assestes/lvsolution/1.webp';
import lvimage2 from '../p-assestes/lvsolution/2.webp';
import lvimage3 from '../p-assestes/lvsolution/3.webp';
import lvimage4 from '../p-assestes/lvsolution/4.webp';
import lvimage5 from '../p-assestes/lvsolution/5.webp';
import lvimage6 from '../p-assestes/lvsolution/6.webp';


const lvData = [
  {
    id: 1,
    image: lvimage1,
    description: "Fixed type LT switchgears & instrumentation Panel",
    info: "LT Panel is an electrical distribution  board that receives power from a  generator or transformer and distributes  it to various equipment and distribution  boards. LT panels are designed to work  with low electricity consumption, which  makes them cost-effective"
  },
  {
    id: 2,
    image: lvimage2,
    description: "Automatic Power Factor Correction Panels [APFC] for HT & LT",
    info: "These electrical control panels  maintain the power factor of the  system automatically by switching  the capacitor banks as per the  needs of the system. The capacitors  are controlled by a controller which  continuously monitors the relative  power demand."
  },
  {
    id: 3,
    image: lvimage3,
    description: "LT PCC/MCC panel",
    info: "This panel is installed near a power  source, hence the fault level is high. The  busbar system in PCC is designed to suit  the fault level as well as the temperature  rise to 40°C above ambient. Various  protections, viz., short circuit, overload,  earth fault, under cvoltage, etc., are  provided to protect the source and  equipment"
  },
  {
    id: 4,
    image: lvimage4,
    description: "ATS Control Panel",
    info: "The ATS Control Panel is a high performance digital controller for  changing the supply power and  generator supply. It also includes an  automated transfer switch, which is  necessary in buildings in the event of a  power outage. We provide an ATS  Control Panel that can automatically  switch loads from mains power to  emergency power. "
  },
  {
    id: 5,
    image: lvimage5,
    description: "Auto Main Failure (AMF) Panels",
    info: "These panels are designed to restore  power during power failures and are  installed between the generators and  the main power supply. When the  main supply failure occurs, the panel  disconnects the main from the load,  activates the generator, and shifts the  load to the generator output."
  },
  {
    id: 6,
    image: lvimage6,
    description: " Distribution board",
    info: "A distribution board, which is fed from the  transformer, distributes the power to  numerous individual circuits, or DBs. A  wide range of compact, elegant, and  economical DBs with unique features,  designed and engineered to provide user  safety, convenience, and operational /  maintenance advantages are offered. "
  }
];

export default lvData;
