import React from 'react';
import './ResearchDev.css';
import rdimage from '../w-assests/rdbanner.webp';

const ResearchDev = () => {
  return (
    <div className="research-dev-wrapper margin-top">
      <div className="research-dev-image" data-aos="fade-up" data-aos-duration="1000">
        <img src={rdimage} alt="Research Dev" />
      </div>
      <div className="research-dev-content" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="300">
        <div className='research-devmain'>
          <div className="research-dev-para">
            Our advanced R&D wing is manned by expert engineers who are well-versed with serious research works. R&D department work in close coordination with production engineers and allied professionals in order to suggest them on redefining existing production procedure. Providing valuable advice on various advanced technologies for the production line which can result in more efficient and quality switchgear products.
          </div>
          <div className="research-dev-para">
           Our R&D experts have facilitated minimization on the loss of materials and proficient use of available resources. Our R&D department enables us to lead ahead of our competitors in the global market through high-quality achievements and ISO certification.
          </div>
        </div>
        <div className="research-dev-boxes">
          <div className="rd-box" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="600">
            <div className="rd-box-heading">
              In-House Engineering & Design Team
            </div>
            <div className="rd-box-content">
              <p>We have a complete in-house Experienced Engineering & Design team; The team is fully capable to Engineer & Design Electrical requirements of the projects. We offer the latest Software & Tools like E-plan, AutoCAD, SolidWorks, ERP etc.</p>
            </div>
          </div>
          <div className="rd-box" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="900">
            <div className="rd-box-heading">
              Safety
            </div>
            <div className="rd-box-content">
              <p>Safe, Environmentally Sustainable and High-Quality Project outcomes are the result of our pursuit of excellence in line with our core values. Our systems have been simplified over time to deliver all the requirements to complete a successful zero harm project. Our Team is trained in the requirements of the safety systems and strive for excellence in all aspects of safety.</p>
            </div>
          </div>
        </div>
        {/* <div className="research-dev-images">
          <img src={researchImage1} alt="Research Image 1" />
          <img src={researchImage1} alt="Research Image 2" />
        </div> */}
      </div>
    </div>
  );
};

export default ResearchDev;
