import React, { useEffect } from 'react';
import './ContactUs2Comp.css';
import linkedinIcon from './contact-assests/social-icon/Group.svg';
import facebookIcon from './contact-assests/social-icon/logos_facebook.svg';
import instagramIcon from './contact-assests/social-icon/skill-icons_instagram.svg';
import twitterIcon from './contact-assests/social-icon/x.svg';
import { AiOutlineMail, AiOutlinePhone } from 'react-icons/ai';
import AOS from 'aos';
import 'aos/dist/aos.css';

const ContactUs2Comp = () => {
  useEffect(() => {
    AOS.init({
      duration: 3000,
      easing: 'ease-in-out',
      once: false // Allow animations to occur every time
    });
  }, []);

  return (
    <div className="contact-us2-container">
      <div className="contact2-left-section" data-aos="fade-right" data-aos-delay="300">
        <p className="address-text">
          <span> Unit I</span><br/> Survey No. 77/5, Dangat Estate, NDA Road,
          <br />
          A/P - Shivane, Tal. Haveli Dist., Pune - 411023,
          <br />
          Maharashtra, INDIA.
          <br />
          Plant & Office Area Unit I: 
          Plant: 45000 sq.ft
        </p>
      </div>
      <div className="contact2-right-section" data-aos="fade-left" data-aos-delay="300">
        <p className="address-text">
          <span>Unit II</span><br/> Gate No. 136, A/P, Varve Bk., Tal. Bhor,
          <br />
          Pune – 412205, Maharashtra, INDIA.
          <br />
          Plant & Office Area Unit II: Plant: 68000 sq.ft
          <br />
        </p>
      </div>
    </div>
  );
};

export default ContactUs2Comp;
