import productImage1 from '../p-assestes/1.webp';
import productImage2 from '../p-assestes/2.webp';
import productImage3 from '../p-assestes/3.webp';
import productImage4 from '../p-assestes/4.webp';
import productImage5 from '../p-assestes/5.webp';
import productImage6 from '../p-assestes/6.webp';
import productImage7 from '../p-assestes/7.webp';
import productImage8 from '../p-assestes/8.webp';
import productImage9 from '../p-assestes/9.webp';
import productImage10 from '../p-assestes/10.webp';
import productImage11 from '../p-assestes/11.webp';
import productImage12 from '../p-assestes/12.webp';
import productImage13 from '../p-assestes/13.webp';
import productImage14 from '../p-assestes/14.webp';
import productImage15 from '../p-assestes/15.webp';
import productImage16 from '../p-assestes/16.webp';
import productImage17 from '../p-assestes/17.webp';
import productImage18 from '../p-assestes/18.webp';
import productImage19 from '../p-assestes/19.webp';

const productsData = [
  {
    id: 1,
    image: productImage1,
    description: "VCB breaker panel up to 33KV (Indoor /Outdoor type)",
    info: "Our VCB panels are trusted for their  durability, safety, client-specific  requirements, cost-efficiency, and  customization. VCBs suitable for  indoor and outdoor applications are  available. Its rating ranges from  3.3KV to 33KV, and its current rating  is 630A to 3150A and 4000A with  forced cooling."
  },
  {
    id: 2,
    image: productImage2,
    description: "Vacuum contractor panel for Motor Capacitor & transformer up to 11KV",
    info:"It is suitable for indoor and outdoor  applications. Its rating ranges from  3.3KV to 11KV, and its current rating is  400A . This VC panel can be used for  switching the motor, capacitor bank, and transformers."
  },
  {
    id: 3,
    image: productImage3,
    description: "33KV, KIOSK for Wind & Solar Application",
    info: "Our 33KV kiosk panels with a simple,  compact, and sturdy design are  compact solutions for substations. They  are integrated units with VCB, CT’s, PT's,  and metering and protection. Available  with OH conductors, they reduce  maintenance, are reliable, and are  compatible with Scada and remote  operation. It is a completely tailor-made  solution. "
  },
  {
    id: 4,
    image: productImage4,
    description: "Unitized Package Sub-Station (USS) up to 33KV",
    info: "The Unitized Package Substation (USS) is a single factory-built substation package with transformer, HT, and LT controls. Is a safe, plug-and-play technology with precise, secure, and completely encased connections They are corrosion-free and available in Both Indoor /Outdoor Version."
  },
  {
    id: 5,
    image: productImage5,
    description: "Field switch for mining application",
    info: "The field switch is used mainly in mine fields to distribute the power for various loads like drill machines,shovels, etc. The field switch comprises an isolater and a vacuum circuit breaker of suitable rating with all protections like CT, PT, relay,metres, etc"
  },
  {
    id: 6,
    image: productImage6,
    description: "Gas Insulated Switchgear (GIS)",
    info: "GIS is metal-encapsulated switchgear  containing high-voltage components  such as circuit breakers and  disconnectors that can be securely  operated in tight places. Our GIS is  dependable, safe, eco-friendly, and  applied in extensions, buildings, roofs,  offshore platforms, industrial, and  hydropower plants."
  },
  {
    id: 7,
    image: productImage7,
    description: "Capacitor Panel up to 36KV",
    info: "Panels are used in the electrical power industry. The product range offered is made using the best quality components and advanced techniques, in compliance with international quality standards. Our product is strictly tested on all parameters by our quality analyzers to ensure its quality and performance."
  },
  {
    id: 8,
    image: productImage8,
    description:'C&R panel for Local electricity Board as per specification',
    info: "A control and relay panel is designed  to control the associated line or  transformer through outdoor  switchgear at various 11KV and 33KV  zonal substations. The control and  relay panels are used for the control  and monitoring of electrical  equipment such as transformers,  generators, and circuit breakers."
  },
  {
    id: 9,
    image: productImage9,
    description: "Unit control Desk",
    info: "We have a large selection of unit control desks that come in a variety of sizes and configurations. We can also adopt the shape, size, style, and operation of these control panels to meet the needs of our clients, making us a popular choice across a wide range of industries"
  },
  // {
  //   id: 10,
  //   image: productImage10,
  //   description: "Remote control Panel",
  //   info: "Quisque vehicula neque ac metus gravida sollicitudin. Fusce convallis facilisis luctus. Aenean efficitur felis at sem auctor fermentum. Aliquam tincidunt ligula vel nulla consectetur, non efficitur purus auctor. Integer ultricies eget dolor ac congue."
  // },
  {
    id: 11,
    image: productImage11,
    description: "FCMA Soft starter for HT & LT for 415V to 12KV",
    info: "FCMA soft starters are used to reduce the starting current of the motor. It provides soft starting for motors. Our manufactured soft starter panel is easy to install and fix. The offered soft starter panel is available at a very cost-effective price. We can supply line-side as well as neutral-side soft-starters."
  },
  {
    id: 12,
    image: productImage12,
    description: "Medium voltage DOL starter",
    info: "As the name suggests, direct-on-line starting means that the motor is started by connecting it directly to the supply at the rated voltage.Direct-on-line starting (DOL) is suitable for stable supplies and small motors."
  },
  {
    id: 13,
    image: productImage13,
    description: "On load Isolator (LBS) 400A, 3.3KV to 36KV Manual & motorized",
    info: "We provide compact modular load  break switch panels in a metal  covered box that is extendable on both  sides. The modular load break switch  panels can be employed in different  ways, including ring main systems,  multi-panels, etc. Transformers,  overhead lines, capacitors, ring mains,  and cables can all be switched with a  load break switch."
  },
  {
    id: 14,
    image: productImage14,
    description: "Off load Isolator 3150A, 3.3KV to 36KV Manual & motorized",
    info: "We offer off-load isolation from 3.3KV to 36KV in manual and motorized operation. Compact modular off-load isolator panels in a metal-covered box that may be extended on both sides are available. Ring Main Systems, Multi Panels, and other applications can use the modular off-load isolator panels."
  },
  {
    id: 15,
    image: productImage15,
    description: "Ring main unit [RMU] up to 33KV (indoor /outdoor type)",
    info: "RMU cabinet, or medium voltage cabinet  is integrated electrical  equipment to  connect, measure, and integrate a  fixed-type breaker with a transformer  protection function. RMU cabinets are  small in size, highly reliable, safe, easy to  maintain, easy to replace, and easy to  expand. Suitable for both indoor and  outdoor installations."
  },
  {
    id: 16,
    image: productImage16,
    description: "Switch fuse unit (SFU) up to 33KV (indoor /outdoor type)",
    info: "This switch fuse unit has a load  break switch and fuse arrangement,  and if required, it will have an earth  switch as well. suitable for indoor  and outdoor applications. "
  },
  {
    id: 17,
    image: productImage17,
    description: "Metering kiosk with isolator combine unit to 33kv ",
    info: "Our 33KV kiosk panels with a simple,  compact, and sturdy design are  compact solutions for substations. They  are integrated units with VCB, CT’s, PT's,  and metering and protection. Available  with OH conductors, they reduce  maintenance, are reliable, and are  compatible with Scada and remote  operation. It is a completely tailor-made  solution. "
  },
  {
    id: 18,
    image: productImage18,
    description: "Temperature Scanner Panel",
    info: "We are using a microcontroller based temperature scanner. It is  designed for fast and accurate  measurement and control of the  process temperature. The scanner  monitors and displays the process  temperature as well as does  ON/OFF control according to the  programmed set point. "
  },
  {
    id: 19,
    image: productImage19,
    description: "NGR panel",
    info: "Neutral grounding resistors, or NGR, are used in an electrical power system to protect the star-connected alternator, generator, or transformer. NGR grounding systems provide more benefits than both grounded and solidly grounded systems.Protection against transient over voltages."
  }
];

export default productsData;
