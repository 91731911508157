// Home.js

import React from 'react';
import Header from '../Components/Header/Header.js';

import Footer from '../Components/Footer/Footer.js'
import MvSolution from '../Components/Products/MvSolution/MvSolution.js';

function MvSolutionPage() {
  return (
    <div className="">
      <Header/> 
     <MvSolution/>
     <Footer/>
    </div>
  );
}

export default MvSolutionPage;
