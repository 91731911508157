import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import './ProductDetail.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import prevIcon from '../p-assestes/prev.png';
import nextIcon from '../p-assestes/next.png';
import productsData from './productsData';
import hydroCoGenData from './hydroCoGenData';
import { useParams } from 'react-router-dom';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';

const ProductDetail = () => {
  const { category, productId } = useParams();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [productDetail, setProductDetail] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const data = category === 'product' ? productsData : hydroCoGenData;

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    const filteredProducts = data.filter(product => 
      product.description.toLowerCase().includes(term.toLowerCase())
    );

    setFilteredData(filteredProducts);
  };

  useEffect(() => {
    const index = filteredData.findIndex(p => p.id === parseInt(productId));
    if (index !== -1) {
      setCurrentImageIndex(index);
      setProductDetail(filteredData[index]);
    }
  }, [category, productId, filteredData]);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const prevImage = () => {
    if (currentImageIndex > 0) {
      const newIndex = currentImageIndex - 1;
      setCurrentImageIndex(newIndex);
      setProductDetail(filteredData[newIndex]);
    }
  };
  
  const nextImage = () => {
    if (currentImageIndex < filteredData.length - 1) {
      const newIndex = currentImageIndex + 1;
      setCurrentImageIndex(newIndex);
      setProductDetail(filteredData[newIndex]);
    }
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'ArrowLeft') {
        prevImage();
      } else if (event.key === 'ArrowRight') {
        nextImage();
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [currentImageIndex, prevImage, nextImage]);

  if (!productDetail || productDetail.id === 10) {
    const nextIndex = currentImageIndex + 1;
    if (nextIndex < filteredData.length) {
      setCurrentImageIndex(nextIndex);
      setProductDetail(filteredData[nextIndex]);
    }
    return null; // Return null instead of showing loading state
  }
  const showPrev = filteredData.length > 1 && currentImageIndex > 0;
  const showNext = filteredData.length > 1 && currentImageIndex < filteredData.length - 1;

  return (
    <div className="ProductDetailpage">
      <Header /> 
      
      <div className="product-detail-wrapper">
        

        <h2 className="product-detail-title">Product Details:</h2>
        <div className="carousel">
          {showPrev && (
            <img 
              src={prevIcon} 
              className="carousel-icon prev-icon" 
              onClick={prevImage} 
              alt="Previous"
            />
          )}
          <div className="product-info">
            <img src={productDetail.image} alt={productDetail.description} className="carousel-image" />
            <p className="product-description-class">{productDetail.description}</p>
          </div>
          {showNext && (
            <img 
              src={nextIcon} 
              className="carousel-icon next-icon" 
              onClick={nextImage} 
              alt="Next"
            />
          )}
        </div>

        <div className='product-detail-section'>
          <div className="about-product-info">
            <div className="about-product-content">
              <div className="about-product-label">About Product:</div>
            </div>
          </div>
          <div className="product-detail-info">
            <div className="product-detail-text">{productDetail.info}</div>
            <Link to="/contact-us" className="enquiry-button">Enquiry</Link>
          </div>
        </div>
      </div>
  
      <Footer /> 
    </div>
  );
};

export default ProductDetail;
