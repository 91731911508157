import React from 'react';
import Header from '../Components/Header/Header.js';
import ContactUs1Comp from '../Components/ContactUs/ContactUs1Comp.jsx';
import ContactUs2Comp from '../Components/ContactUs/ContactUs2Comp.jsx';
import Contact3Comp from '../Components/ContactUs/Contact3Comp.jsx';
import MiniFooter from '../Components/ContactUs/MiniFooter.jsx';

function ContactUsPage() {
  return (
    <div className="">
      <Header/> 
      <div className="content">
        <ContactUs1Comp/>
        <ContactUs2Comp/>
        <Contact3Comp/>
      </div>
      <MiniFooter/>
    </div>
  );
}

export default ContactUsPage;
