import React from 'react';
import Header from '../Components/Header/Header.js';
import Footer from '../Components/Footer/Footer.js';
import Award1Comp from '../Components/Awards/Award1Comp.js';

function AwardsPage() {
  return (
    <div className="">
      <Header/> 
      <div className="content">
        <Award1Comp/>
      </div>
      <Footer/>
    </div>
  );
}

export default AwardsPage;
