import React, { useEffect } from 'react';
import './Whowe4Comp.css';
import image1 from '../w-assests/strength.png';
import image2 from '../w-assests/showsthrength.webp';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import AOS from 'aos';
import 'aos/dist/aos.css';

const pointsData = [
  {
    icon: <IoMdCheckmarkCircleOutline />,
    text: 'A dedicated team of 150 members and powered by technical up gradation-integration-innovation-transparency and integrity at each stage of our growth.'
  },
  {
    icon: <IoMdCheckmarkCircleOutline />,
    text: 'Testing Facility, Wide Range of Testing Equipment’s'
  },
  {
    icon: <IoMdCheckmarkCircleOutline />,
    text: 'All Equipment’s are type tested at renowned NABL Labs i.e., ERDA, CPRI and ASTA CERTIFICATION'
  },
  {
    icon: <IoMdCheckmarkCircleOutline />,
    text: 'Delivery as per customer requirement.'
  },
  {
    icon: <IoMdCheckmarkCircleOutline />,
    text: 'A Inhouse Design Software (E-plan, ERP, Solid works and Auto Cad)ll Equipment’s are type tested at renowned NABL Labs i.e., ERDA, CPRI and ASTA CERTIFICATION'
  },
  {
    icon: <IoMdCheckmarkCircleOutline />,
    text: 'Cost Competitive Solution'
  },
  {
    icon: <IoMdCheckmarkCircleOutline />,
    text: 'Inhouse Design Software (E-plan, ERP, Solid works and Auto Cad).'
  },
  {
    icon: <IoMdCheckmarkCircleOutline />,
    text: 'Dedicated Service Team for Commissioning'
  },
  {
    icon: <IoMdCheckmarkCircleOutline />,
    text: 'All latest Machineries'
  }
];

const Whowe4Comp = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // animation duration in milliseconds
      easing: 'ease-in-out', // animation easing
      once: false, // whether animation should only happen once
    });
  }, []);

  return (
    <div className="whowe4comp-wrapper">
  <div className="whowe4comp-image1" data-aos="fade-up" data-aos-delay="100">
    <img src={image1} alt="Image 1" />
  </div>
  <div className="whowe4comp-image2" data-aos="fade-up" data-aos-delay="200">
    <img src={image2} alt="Image 2" />
  </div>
  <p className="whowe4comp-para1" data-aos="fade-up" data-aos-delay="300">
    HT Switchgears have a manufacturing facility with advanced manufacturing equipment and processes at Unit No1. located at Shivane - Pune, Maharashtra, INDIA, having a built-up area of <b>45000 sq.ft</b>  and Unit II: A/P, Varve Bk., Tal. Bhor, Pune – 412205, Maharashtra, INDIA, having a built-up area of <b>68000 sq.ft</b>.
  </p>
  <p className="whowe4comp-heading" data-aos="fade-up" data-aos-delay="400">
    HTS has a total built-up area of 1,13,000 Sq. ft.
  </p>


      
      <div className="whowe4comp-points">
        {pointsData.map((point, index) => (
          <div className="point" key={index} data-aos="fade-up" data-aos-delay={100 * index}>
            <div className="icon-container">
              <span className="icon-wrapper">{point.icon}</span>
            </div>
            <p>{point.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Whowe4Comp;
