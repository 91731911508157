import React from 'react';
import './MiniFooter.css';
import logo from '../assests/hts-logo.png';

const MiniFooter = () => {
  return (
    <div className="mini-footer">
      <img src={logo} alt="Logo" className="footer-logo" />
      <nav className="footer-nav">
        <a href="/" className="footer-link mini-footer-link">Home</a>
        <a href="/who-we-are" className="footer-link mini-footer-link">About Us</a>
        <a href="/mv-solutions" className="footer-link mini-footer-link">Products</a>
        <a href="/awards" className="footer-link mini-footer-link">Award</a>
        <a href="/clients" className="footer-link mini-footer-link">Clients</a>
      </nav>
    </div>
  );
}

export default MiniFooter;
