import React, { useState, useEffect } from 'react';
import './LvSolution.css'; 
import searchIcon from '../p-assestes/mynaui_search.png';
import lvbanner from '../p-assestes/lv-banner.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import productsData from './productsData';
import ltStarterData from './LtStarterData';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

const LvSolution = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredProducts = productsData.filter(product =>
    product.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredLtStarterProducts = ltStarterData.filter(product =>
    product.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    AOS.init({
      duration: 1000, // animation duration in milliseconds
      easing: 'ease-in-out', // animation easing
      once: false , // whether animation should only happen once
    });
  }, []);

  return (
    <div className="lv-solution-wrapper">
      <div className="search-bar">
        <img src={searchIcon} alt="Search Icon" className="search-icon" />
        <input 
          type="text" 
          placeholder="Search For Product" 
          className="search-input"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
      <div className="product-title">Product <FontAwesomeIcon icon={faAngleRight} className="arrow-icon" /> LV Solution <span>(total {filteredProducts.length} Products)</span></div>
      <div className="product-section">
        <a href='http://localhost:3000/lv-product-detail/lv/1'>
          <img src={lvbanner} alt="Product" className="product-image" />
        </a>
      </div>
      <div className="product-list">
        {filteredProducts.map((product, index) => (
          <div className="product-box" key={product.id} data-aos="fade-up" data-aos-delay={100 * index}>
            <img src={product.image} alt="Product" className="product-box-image" />
            <div className="product-description">{product.description}</div>
            <Link to={`/lv-product-detail/lv/${product.id}`} className="explore-link">
              <button className="explore-button">
                Explore 
                <FontAwesomeIcon icon={faAngleRight} className="button-icon" />
              </button>
            </Link>
          </div>
        ))}
      </div>
      <div className="product-section">
        <div className="product-title">Product <FontAwesomeIcon icon={faAngleRight} className="arrow-icon" /> LT Starter <span>(total {filteredLtStarterProducts.length} Products)</span></div>
        <div className="product-list">
          {filteredLtStarterProducts.map((product, index) => (
            <div className="product-box" key={product.id} data-aos="fade-up" data-aos-delay={100 * index}>
              <img src={product.image} alt="Product" className="product-box-image" />
              <div className="product-description">{product.description}</div>
              <Link to={`/lv-product-detail/lt/${product.id}`} className="explore-link">
                <button className="explore-button">
                  Explore 
                  <FontAwesomeIcon icon={faAngleRight} className="button-icon" />
                </button>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LvSolution;
