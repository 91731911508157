import ltimage1 from '../p-assestes/ltstarter/1.webp';
import ltimage2 from '../p-assestes/ltstarter/2.webp';
import ltimage3 from '../p-assestes/ltstarter/3.webp';
import ltimage4 from '../p-assestes/ltstarter/4.webp';

const ltData = [
  {
    id: 1,
    image: ltimage1,
    description: "DOL Starter, star-Delta starter panel",
    info: "This panel consists of various types of  DOL starters and star-delta starters. A  motor starter panel is a specialized  device designed to manage and control  electric motors in industrial  applications. It is an essential  equipment that ensures safe motor  operation, enhances efficiency, and  extends the lifespan of motors."
  },
  {
    id: 2,
    image: ltimage2,
    description: "Soft Starter",
    info: "Soft starters are used to reduce  the starting current of the motor. It  provides soft starting for motors. Our  manufactured soft starter panel is  easy to install and fix. The offered soft  starter panel is available at a very  cost-effective price. We can supply  line-side as well as neutral-side  soft-starters"
  },
  {
    id: 3,
    image: ltimage3,
    description: "VFD Panel",
    info: "A VFD panel, short for Variable Frequency Drive panel, is an electrical control system used to regulate the speed and torque of AC induction motors. It achieves this by adjusting the frequency and voltage of the electrical power supplied to the motor.These panels typically consist of variable frequency Drive (VFD) unit, power circuit components like rectifiers and IGBTs, control circuity with microprocessor and input/output modules, a Human-Machine Interface (HMI) for user interaction, and devices such as fuses and overload relays.VFD panels find widespread use in industries and commercial application where precise control of motor speed is essential, offering benefits such as energy saving, improved process efficiency, and reduced mechanical stress on equipment"
  },
  {
    id: 4,
    image: ltimage4,
    description: "Auto-transformer starter",
    info: "Auto-transformer Starter is used to start  the induction motor. It reduces the  starting current of the motor. We can  adjust the starting current through the  variable taps available at the  auto-transformer starter.."
  }
];

export default ltData;
