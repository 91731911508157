import React ,{ useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route ,useLocation } from 'react-router-dom';
import ScrollToTop from './Components/ScrollToTopButton/ScrollToTopButton';
import Home from './pages/Home';
import WhoWeAre from './pages/AboutPage';
import WhyHtsMainPage from './pages/WhyHtsMainPage';
import ReasechDevPage from './pages/R&Dpage';
import WhowearePage from './pages/whowearePage';
import MvSolutionPage from './pages/MvSolutionPage';
import ProductDetail from './Components/Products/ProductDetail/ProductDetail';
import LvSolutionPage from './pages/LvSolutionPage';
import LvProductDetail from './Components/Products/LvProductDetail/LvProductDetail';
import BusDuctPage from './pages/BusDuctPage';
import BusDuctProductDetail from './Components/Products/BusDuct/BusDuctProductDetail';
import AwardsPage from './pages/AwardsPage';
import ContactUsPage from './pages/ContactUsPage';
import SectorWeWorkPage from './pages/SectorWeWorkPage';
import Clients from './pages/clients';
import SectorWeWorkMain from './Components/SectorWeWorkMain/SectorWeWorkMain';
import Client2Comp from './Components/Clients/Client2Comp';
import ContactUs1Comp from './Components/ContactUs/ContactUs1Comp';
import Retrofitting from './Components/Products/BusDuct/Retrofitting';
import SpareService from './Components/Products/BusDuct/SpareService';
// import StickyChat from './Components/StickyChat/StickyChat';


const ScrollTo = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};


function App() {
 
  return (
    <Router>
    <ScrollTo/>
      <ScrollToTop/>
      {/* <StickyChat/> */}
      <div className="App">
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<WhoWeAre />} />
            <Route path="/why-hts" element={<WhyHtsMainPage />} />
            <Route path="/r-and-d-approvals" element={<ReasechDevPage />} />
            <Route path="/who-we-are" element={<WhowearePage />} />
            <Route path="/mv-solutions" element={<MvSolutionPage />} />
            <Route path="/lv-solutions" element={<LvSolutionPage />} />
            <Route path="/mv-product/:category/:productId" element={<ProductDetail />} />
            <Route path="/lv-product-detail/:category/:productId" element={<LvProductDetail />} />
            <Route path="/bus-duct" element={<BusDuctPage />} />
            <Route path="/busduct-detail/:category/:productId" element={<BusDuctProductDetail />} />
            <Route path="/retrofitting" element={<Retrofitting />} />
            <Route path="/spare-services" element={<SpareService/>} />


            <Route path="/awards" element={<AwardsPage/>} />
            <Route path="/contact-us" element={<ContactUsPage/>} />
            <Route path="/sector-we-work" element={<SectorWeWorkMain/>} />
            <Route path="/clients" element={<Clients/>} />
            <Route path="/our-clients" element={<Client2Comp/>} />
            <Route path="/contact-form" element={<ContactUs1Comp/>} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
