import React, { useEffect } from 'react';
import './Whowe3Comp.css';
import qualityImage from '../w-assests/image-3.webp';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Whowe3Comp = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // animation duration in milliseconds
      easing: 'ease-in-out', // animation easing
      once: false, // whether animation should only happen once
    });
  }, []);

  return (
    <div className="whowe3comp-wrapper">
      <div className="whowe3comp-content">
        <div className="whowe3comp-quality" data-aos="fade-right">
          <h2 className="quality-heading">Quality Policy</h2>
          <ul className="quality-points">
            <li>We have maintained High Quality Standards, with firm belief in Strict Quality Management Systems. In order to assure Quality Standards in each of our product, we have appointed Quality Inspectors, who are consistently engaged various Quality Tests ensuring High Level of Reliability.</li>
            <li>Various Regular Tests over the entire manufacturing procedure are set from Initial stage till Finished Product, as per relevant IS and IEC standards.</li>
          </ul>
        </div>
        <div className="whowe3comp-quality-image" data-aos="fade-left">
          <div className="image-overlay"></div>
          <img src={qualityImage} alt="Quality" className="quality-image" />
        </div>
      </div>
    </div>
  );
};

export default Whowe3Comp;
