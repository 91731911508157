import RetroImage from '../p-assestes/retrofitting.webp';

const retrofittingData = [
  {
    id: 1,
    image: RetroImage,
    description: "Retrofitting of breaker Panels",
    info:"Retrofit breakers are typically used to  replace obsolete devices with newer  production models. They are  mechanically and electrically designed  to work with the existing site solution.  HTS's service professionals conduct site  audits on existing installations to  examine the equipment's condition,  offer the best solution, and back up the  best investment decision. "
  }
];

export default retrofittingData;
