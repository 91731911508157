import React from 'react';
import './Client1Comp.css';
import firstImage from './client-assests/banner.png';
import lastImage from './client-assests/Frame 277.png'; 
import flag1 from './client-assests/HT Switchgears/1.png';
import flag2 from './client-assests/HT Switchgears/2.png';
import flag3 from './client-assests/HT Switchgears/3.png';
import flag4 from './client-assests/HT Switchgears/4.png';
import flag5 from './client-assests/HT Switchgears/5.png';
import flag6 from './client-assests/HT Switchgears/6.png';
import flag7 from './client-assests/HT Switchgears/7.png';
import flag8 from './client-assests/HT Switchgears/8.png';
import flag9 from './client-assests/HT Switchgears/9.png';
import flag10 from './client-assests/HT Switchgears/10.png';
import flag11 from './client-assests/HT Switchgears/11.png';
import flag12 from './client-assests/HT Switchgears/12.png';
import flag13 from './client-assests/HT Switchgears/13.png';
import flag14 from './client-assests/HT Switchgears/14.png';
import flag15 from './client-assests/HT Switchgears/15.png';
import flag16 from './client-assests/HT Switchgears/16.png';

const Client1Comp = () => {
  return (
    <div className="client1-container">
    <div className='firstimage-cnt'>
    <img src={firstImage} alt="First" className="first-image-1" />

    </div>
      
           <img src={lastImage} alt="First" className="first-image1 " />

    </div>
  );
};

export default Client1Comp;
