import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

import './WhyChooseUs.css';
import whychoose from '../../assests/whychoose.svg';
import bulletPointImage from '../../assests/points.png';
import redarrow from '../../assests/redarrow.png';
import { SlArrowRightCircle } from "react-icons/sl";

const WhyChooseUs = () => {
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({
      duration: 1000, 
      easing: 'ease-in-out', 
      once: false, 
    });
  }, []);

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className="why-choose-us-container">
      <div className="whychoose-image-container" data-aos="fade-up">
        <svg width="95%" height="auto" className="left-image " data-aos="fade-up">
          <image href={whychoose} width="100%" height="360px" className='whychoose-img zoomer-mover' />
        </svg>
      </div>
      <div className="points-container">
        <div className="point" data-aos="fade-up">
          <div className="bullet-image"><img src={bulletPointImage} className="bullet-point" /></div>
          <div className="point-text">Our goods are made in accordance with customer specifications.</div>
        </div>
        <div className="point" data-aos="fade-up" data-aos-delay="200">
          <div className="bullet-image"><img src={bulletPointImage} className="bullet-point" /></div>
          <div className="point-text">Prompt delivery of products</div>
        </div>
        <div className="point" data-aos="fade-up" data-aos-delay="400">
          <div className="bullet-image"><img src={bulletPointImage} className="bullet-point" /></div>
          <div className="point-text">An innovative manufacturing facilities arrangement</div>
        </div>
        <div className="point" data-aos="fade-up" data-aos-delay="600">
          <div className="bullet-image"><img src={bulletPointImage} className="bullet-point" /></div>
          <div className="point-text">Well-built manufacturing plant.</div>
        </div>
        <div className="point" data-aos="fade-up" data-aos-delay="800">
          <div className="bullet-image"><img src={bulletPointImage} className="bullet-point" /></div>
          <div className="point-text">Complying with global quality standards.</div>
        </div>
        <div className="point" data-aos="fade-up" data-aos-delay="1000">
          <div className="bullet-image"><img src={bulletPointImage} className="bullet-point" /></div>
          <div className="point-text">Cost effective.</div>
        </div>
        <button className="learn-more-button" onClick={() => handleNavigation('/why-hts')}>
          Learn More 
          <SlArrowRightCircle className="learn-more-icon" />
        </button>
      </div>
    </div>
  );
};

export default WhyChooseUs;
