import React, { useState, useEffect } from 'react';
import './LvProductDetail.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import prevIcon from '../p-assestes/prev.png';
import nextIcon from '../p-assestes/next.png';
import ltData from './ltData';
import lvData from './lvData';
import { useParams } from 'react-router-dom';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';

const LvProductDetail = () => {
  const { category, productId } = useParams();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [productDetail, setProductDetail] = useState(null);

  const data = category === 'lt' ? ltData : lvData;

  useEffect(() => {
    const index = data.findIndex(p => p.id === parseInt(productId));
    if (index !== -1) {
      setCurrentIndex(index);
      setProductDetail(data[index]);
    }
  }, [category, productId, data]);

  const handlePrev = () => {
    if (currentIndex > 0) {
      const newIndex = currentIndex - 1;
      setCurrentIndex(newIndex);
      setProductDetail(data[newIndex]);
    }
  };

  const handleNext = () => {
    if (currentIndex < data.length - 1) {
      const newIndex = currentIndex + 1;
      setCurrentIndex(newIndex);
      setProductDetail(data[newIndex]);
    }
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'ArrowLeft') {
        handlePrev();
      } else if (event.key === 'ArrowRight') {
        handleNext();
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handlePrev, handleNext]);

  if (!productDetail) {
    return <div>Loading...</div>;
  }

  const showPrev = currentIndex > 0;
  const showNext = currentIndex < data.length - 1;

  return (
    <div className="ProductDetailpage">
      <Header /> 
      
      <div className="product-detail-wrapper">
        <h2 className="product-detail-title">Product Details:</h2>
    
        {/* Carousel */}
        <div className="carousel">
          {showPrev ? (
            <img 
              src={prevIcon} 
              className="carousel-icon" 
              onClick={handlePrev} 
              alt="Previous" 
            />
          ) : (
            <img 
              src={prevIcon} 
              className="carousel-icon disabled" 
              alt="Previous" 
              disabled
            />
          )}
          <div className="product-info">
            <img src={productDetail.image} alt={productDetail.description} className="carousel-image" />
            <p className="product-description-class">{productDetail.description}</p>
          </div>
          {showNext ? (
            <img 
              src={nextIcon} 
              className="carousel-icon" 
              onClick={handleNext} 
              alt="Next" 
            />
          ) : (
            <img 
              src={nextIcon} 
              className="carousel-icon disabled" 
              alt="Next" 
              disabled
            />
          )}
        </div>
    
        <div className='product-detail-section'>
          <div className="about-product-info">
            <div className="about-product-content">
              <div className="about-product-label">About Product:</div>
            </div>
          </div>
          <div className="product-detail-info">
            <div className="product-detail-text">{productDetail.info}</div>
            <a href="/contact-us" className="enquiry-button">Enquiry</a>
          </div>
        </div>
      </div>

      <Footer /> 
    </div>
  );
};

export default LvProductDetail;
