import React, { useEffect } from 'react';
import './Whowe2Comp.css';
import missionImage from '../w-assests/mission.webp';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Whowe2Comp = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // animation duration in milliseconds
      easing: 'ease-in-out', // animation easing
      once: false, // whether animation should only happen once
    });
  }, []);

  return (
    <div className="whowe2comp-wrapper">
      <div className="whowe2comp-content">
        <div className="whowe2comp-mission-image" data-aos="fade-left" data-aos-delay="1000">
          <div className="gray-bg-box"></div>
          <img src={missionImage} alt="mission" />
        </div>
        <div className="whowe2comp-mission" data-aos="fade-right" data-aos-delay="1500">
          <h2 className="mission-heading">Our Mission</h2>
          <ul className="mission-points">
            <li>H.T. SWITCHGEARS will endeavor to achieve this vision by continually upgrading its knowledge/ core competence through indigenous research & development, human resource development, promoting, team spirit & entrepreneurship among its employees in order to achieve total customer satisfaction.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Whowe2Comp;
