

import React from 'react';
import Header from '../Components/Header/Header.js';
import Footer from '../Components/Footer/Footer.js'
import Client1Comp from '../Components/Clients/Client1Comp.jsx';


function Clients() {
  return (
    <div className="">
      <Header/> 
   <Client1Comp/>
  
     <Footer/>
    </div>
  );
}

export default Clients;
