import React, { useEffect } from 'react';
import './Unit1.css';
import { SlArrowRightCircle } from "react-icons/sl";
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS CSS

import companyImage from '../H-asset/unit-1.webp'; 
import arrow from '../../assests/arrow.png';
import { useNavigate } from 'react-router-dom'

const Unit1 = () => {
  const navigate = useNavigate(); 

  useEffect(() => {
    AOS.init({
      duration: 1000, // Change the animation duration as needed
      disable: window.innerWidth <= 768, // Disable AOS on screens smaller than or equal to 768px
    });
  }, []);
  
  const handleNavigation = (path) => {
    navigate(path); 
  };

  return (
    <div className="unit1-container" data-aos="fade-up"> {/* Add AOS attribute */}
      <div className="left-content" data-aos="fade-right"> {/* Add AOS attribute */}
        <div className='company-info'>
          <span className='span-red'> HT Switchgears (HTS) </span>is a multi-product, vertically integrated manufacturer. Specialized in entire range of Medium Voltage, Low voltage Switchgears & Services. It is customer-oriented, innovative, and values-driven organization. <span className='span-dark'>HTS was Established in 2006</span>, it is motivated & promoted by first generation entrepreneurs.
        </div>
        
        <button className="about-company-button" onClick={() => handleNavigation('/about-us')} data-aos="zoom-in"> {/* Add AOS attribute */}
          About Company 
          <SlArrowRightCircle className="rightcircle-icon"  />
        </button>
      </div>
      <div className="right-content" data-aos="fade-up"> {/* Add AOS attribute */}
        <img className="company-image zoomer-mover" src={companyImage} alt="Company" />
      </div>
    </div>
  );
};

export default Unit1;
