import React, { useEffect } from 'react';
import './Whowe1Comp.css';
import htsImage from '../w-assests/whowebanner.webp';
import visionImage from '../w-assests/visionupdated.webp';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Whowe1Comp = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // animation duration in milliseconds
      easing: 'ease-in-out', // animation easing
      once: false, // whether animation should only happen once
    });
  }, []);

  return (
    <div className="whowe1comp-wrapper">
      <div className="whowe1comp-banner" data-aos="fade-up" data-aos-once="true">
        <img src={htsImage} alt="HTS" />
      </div>
      <div className="whowe1comp-content">
        <div className="whowe1comp-vision" data-aos="fade-right" data-aos-once="true">
          <h2 className="vision-heading" data-aos-delay="300">Our Vision</h2>
          <ul className="vision-points">
            <li data-aos="fade-up" data-aos-delay="600">HTS shall be Professionally Managed, Customer Driven & Committed to Total Quality.</li>
            <li data-aos="fade-up" data-aos-delay="900">HTS shall be Market Leader with significant Global Perspective in the vertical product segments, we operate.</li>
            <li data-aos="fade-up" data-aos-delay="1200">HTS shall be a leader in the MV, LV, Power Distribution and Control Systems industry with personalized attention catering to the needs of the Electrical Industry.</li>
            <li data-aos="fade-up" data-aos-delay="1500">HTS shall be a Learning Organization while achieving Exponential Growth & Glory, focused on Customer Exultation, Research and Development, World Class Manufacturing Practices & Ethical Value Systems.</li>
            <li data-aos="fade-up" data-aos-delay="1800">HTS shall be an Accountable Corporate Citizen Empowering its Human Resources and all its stakeholders Consistent Growth, Pride, Thrill and Excellence.</li>
          </ul>
        </div>
        <div className="whowe1comp-vision-image" data-aos="fade-left" data-aos-once="true">
          <div className='graybg'></div>
          <img src={visionImage} alt="Vision" className="half-in-half-out" />
        </div>
      </div>
    </div>
  );
};

export default Whowe1Comp;
