import React from 'react';
import Header from '../Components/Header/Header.js';
import Footer from '../Components/Footer/Footer.js';
import About1comp from '../Components/WhoWeAre/About1comp/About1comp.js';
import About2comp from '../Components/WhoWeAre/About2comp/About2comp.js';

function WhoWeAre() {
  return (
    <div className="Home">
      <Header/> 
      <div className="content">
        <About1comp/>
        <About2comp/>
      </div>
      <Footer/>
    </div>
  );
}

export default WhoWeAre;
