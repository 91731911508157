import React, { useState, useEffect } from 'react';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom'; 
import retrofittingData from './retrofittingData'; // Import retrofitting data
import searchIcon from '../p-assestes/mynaui_search.png';

import AOS from 'aos';
import 'aos/dist/aos.css';

const Retrofitting = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredRetrofittingProducts = retrofittingData.filter(product =>
    product.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true,
    });
  }, []);

  return (
    <>
    <Header/>
    <div className="busduct-wrapper"> {/* Using the same class name */}
      <div className="search-bar">
        <img src={searchIcon} alt="Search Icon" className="search-icon" />
        <input 
          type="text" 
          placeholder="Search For Product" 
          className="search-input"
          onChange={handleSearchChange}
        />
      </div>
      
      <div className="product-title">
        Retrofitting :   
        <span>(total {filteredRetrofittingProducts.length} Products)</span>
      </div>
      
      <div className="product-list busdust-list">
        {filteredRetrofittingProducts.map((product, index) => (
          <div className="product-box busdust-box" key={product.id} data-aos="fade-up" data-aos-delay={300 * index}>
            <img src={product.image} className="product-box-image" />
            <div className="product-description">{product.description}</div>
            <Link to={`/busduct-detail/retrofitting/${product.id}`} className="explore-link">
              <button className="explore-button">
                Explore 
                <FontAwesomeIcon icon={faAngleRight} className="button-icon" />
              </button>
            </Link>
          </div>
        ))}
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default Retrofitting;
