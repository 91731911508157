import ltimage1 from '../p-assestes/ltstarter/1.webp';
import ltimage2 from '../p-assestes/ltstarter/2.webp';
import ltimage3 from '../p-assestes/ltstarter/3.webp';
import ltimage4 from '../p-assestes/ltstarter/4.webp';


const ltStarterData = [
  {
    id: 1,
    image: ltimage1,
    description: "DOL Starter, star-Delta starter panel"
  },
  {
    id: 2,
    image: ltimage2,
    description: "Soft Starter"
  },
  {
    id: 3,
    image: ltimage3,
    description: "VFD Panel"
  },
  {
    id: 4,
    image: ltimage4,
    description: "Auto-transformer starter"
  }
];

export default ltStarterData;
