import React, { useEffect } from 'react';
import './About1comp.css';
import mainImage from '../w-assests/mainImage.webp';
import sideImage from '../w-assests/1.webp';
import secondImage from '../w-assests/2.webp';
import bottomImage from '../w-assests/3.webp';
import AOS from 'aos';
import 'aos/dist/aos.css';

const About1comp = () => {
  useEffect(() => {
    AOS.init({ once: false }); // Initialize AOS once
  }, []);

  return (
    <div className='whowe-image '>
      <img src={mainImage} alt="Main" className="main-image" data-aos="fade-up" data-aos-duration="1000" />

      <div className="whowe1-container" data-aos="fade-up" data-aos-duration="1000">
        <div className="whoweare-section">
          <h2>About Us :</h2>
          <div className='whowepara-section'>
            <div className="whoweare-para">
              HT Switchgears (HTS) is a multi-product, vertically integrated manufacturer. Specialized in entire range of Medium Voltage, Low voltage Switchgears & Services. It is customer-oriented, innovative, and values-driven organization. HTS was Established in 2006, it is motivated & promoted by first generation entrepreneurs. A group of technocrats complimented by a dedicated team and powered by technical up gradation-integration-innovation-transparency and integrity at each stage of our growth.
            </div>
          </div>

          <div className="secondary-section" data-aos="fade-up" data-aos-duration="1000">
            <div className="side-images">
              <img src={sideImage} alt="Side" className="side-image" data-aos="fade-right" data-aos-duration="2000" />
              <img src={secondImage} alt="Side" className="side-image" data-aos="fade-left" data-aos-duration="2000" />
            </div>
            <div className="secondary-para"  data-aos="fade-up" data-aos-duration="1000">
              HTS's customer-centric approach has always delighted our customers; signifying a high degree of commitment & integrity by delivering quality products in-time. HTS takes pride in promising our customers, agility and flexibility, that aids the Company to develop and deliver quality products in unbelievable short time period to meet urgency of customer requirement. Our unwavering commitment to customer and vendor support has propelled us to become a leading Electrical Switchboard Manufacturer in Asia Region. We are proud to supply various projects both domestically and internationally.
            </div>
          </div>

          <div className="full-width-para" data-aos="fade-up" data-aos-duration="1000">
            An ISO 9001:2015 certified company, HTS exhibits a noble quality and service. Strict adherence to ISO 9001 system & systematic approach and practices empowers HTS in achieving consistently good ratings from customers. This has enabled HTS to work and grow with renowned Client’s, as our esteemed customers in the fast-paced competition consistently. State-of-the-Art designing, manufacturing & testing facilities along with thrust on quality and technology are giving HTS national and international recognition in the form of Certifications by leading Government Organizations across India and International customers. HTS earnestly & continuously strives to achieve excellence not only in its operations but also in the foray of Corporate Citizenship. With deep respect for social values and human ethos we are committed to improve the Quality of life of its employees through Safe work practices and healthy environment.
          </div>

          <img
      src={bottomImage}
      alt="Side"
      className="full-width-image zoomer-move"
      data-aos="fade-up"
      data-aos-duration="1000"
    />
        </div>
      </div>
    </div>
  );
};

export default About1comp;
