import BusDuctImage from '../p-assestes/Busduct.webp';

const busductData = [
  {
    id: 1,
    image: BusDuctImage,
    description: "HT & LT Busduct up to 33KV (indoor /outdoor type)",
    info:"Bus duct systems are designed to  allow multiple outputs from them  without any power loss. Our  manufactured bus bar chambers  need very little maintenance. We  are one of the most reputed bus  duct suppliers in India, delivering  quality systems of any type,  including segregated, LT (Low  Tension), and MV (Medium Voltage)."
  }
];

export default busductData;
