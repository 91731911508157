import React, { useEffect, useRef } from 'react';
import './Client2Comp.css';
import firstImage from './client-assests/heading.png';
import worldMap from './client-assests/map.webp';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { useNavigate } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

// Import flags
import flag1 from './client-assests/HT Switchgears/1.png';
import flag2 from './client-assests/HT Switchgears/2.png';
import flag3 from './client-assests/HT Switchgears/3.png';
import flag4 from './client-assests/HT Switchgears/4.png';
import flag5 from './client-assests/HT Switchgears/5.png';
import flag6 from './client-assests/HT Switchgears/6.png';
import flag7 from './client-assests/HT Switchgears/7.png';
import flag8 from './client-assests/HT Switchgears/8.png';
import flag9 from './client-assests/HT Switchgears/9.png';
import flag10 from './client-assests/HT Switchgears/10.png';
import flag11 from './client-assests/HT Switchgears/11.png';
import flag12 from './client-assests/HT Switchgears/12.png';
import flag13 from './client-assests/HT Switchgears/13.png';
import flag14 from './client-assests/HT Switchgears/14.png';
import flag15 from './client-assests/HT Switchgears/15.png';
import flag16 from './client-assests/HT Switchgears/16.png';

const countries = [
  { flag: flag1, name: 'Tanzania' },
  { flag: flag2, name: 'Philippines' },
  { flag: flag3, name: 'Georgia' },
  { flag: flag4, name: 'Laos' },
  { flag: flag5, name: 'Sudan' },
  { flag: flag6, name: 'Indonesia' },
  { flag: flag7, name: 'Thailand' },
  { flag: flag8, name: 'Kenya' },
  { flag: flag9, name: 'Nigeria' },
  { flag: flag10, name: 'Russia' },
  { flag: flag11, name: 'Bataan' },
  { flag: flag12, name: 'Srilanka' },
  { flag: flag13, name: 'Nepal' },
  { flag: flag14, name: 'Rawanda' },
  { flag: flag15, name: 'Uganda' },
  { flag: flag16, name: 'Congo' }
];

const Client2Comp = () => {
  const navigate = useNavigate();
  const containerRef = useRef(null);

  useEffect(() => {
    AOS.init({
      duration: 1000,   
      easing: 'ease-in-out',
    });
    //2000

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5 // Trigger when 50% of the component is visible
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          animateFlags();
          entry.target.classList.add('aos-animate'); // Add aos-animate class when in view
        } else {
          entry.target.classList.remove('aos-animate'); // Remove aos-animate class when out of view
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);
    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  const animateFlags = () => {
    const zandaBoxes = document.querySelectorAll('.zanda-box');
    zandaBoxes.forEach((box, index) => {
      setTimeout(() => {
        box.classList.add('aos-animate');
      }, index * 100);
    });
  };

  const handleNavigation = () => {
    navigate('/our-clients');
  };

  return (
    <>
      <Header />

      <div className="client2-container" ref={containerRef}>
        <div className="first-image-container" data-aos="fade-up">
          <img src={firstImage} alt="First" className="first-image" />
        </div>
        <div className="para-container" data-aos="fade-up" data-aos-delay={1000}>
            <div className="para">
              One important and vital element of our orders is exports. The African Subcontinent, the Middle East and Gulf Region, East Asia, are among the regions with recent installations. <br/> In addition to serving a significant number of customers in India, we have exported our manufactured products to several other nations, including
            </div>
          </div>

        <div className="section-container " data-aos="fade-up">
          <div className="map-image-container">
            <img src={worldMap} alt="World Map" className="world-map-image zoomer-mover"  data-aos="fade-up"/>
          </div>

         

          {/* <div className="zanda-container zandasubpage" data-aos="fade-up">
  {countries.map((country, index) => (
    <div key={index} className="zanda-box zandasubbox" data-aos="fade-up" data-aos-delay={index * 100}>
      <img src={country.flag} alt={country.name} className="zanda-image" />
      <div className="zanda-name">{country.name}</div>
    </div>
  ))}
</div> */}

        </div>
      </div>
      <Footer />
    </>
  );
};

export default Client2Comp;
