import React, { useState, useEffect } from 'react';
import './BusDuctProductDetail.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import prevIcon from '../p-assestes/prev.png';
import nextIcon from '../p-assestes/next.png';
import busductData from './busDuctData';
import retrofittingData from './retrofittingData';
import spareServiceData from './spareServiceData';
import { useParams } from 'react-router-dom';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';

const BusDuctProductDetail = () => {
  const { category, productId } = useParams();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [productDetail, setProductDetail] = useState(null);

  let data = [];

  switch (category) {
    case 'busduct':
      data = busductData;
      break;
    case 'retrofitting':
      data = retrofittingData;
      break;
    case 'spareservice':
      data = spareServiceData;
      break;
    default:
      break;
  }

  useEffect(() => {
    const index = data.findIndex(p => p.id === parseInt(productId));
    if (index !== -1) {
      setCurrentImageIndex(index);
      setProductDetail(data[index]);
    }
  }, [category, productId, data]);

  const prevImage = () => {
    if (currentImageIndex > 0) {
      const newIndex = currentImageIndex - 1;
      setCurrentImageIndex(newIndex);
      setProductDetail(data[newIndex]);
    }
  };
  
  const nextImage = () => {
    if (currentImageIndex < data.length - 1) {
      const newIndex = currentImageIndex + 1;
      setCurrentImageIndex(newIndex);
      setProductDetail(data[newIndex]);
    }
  };

  if (!productDetail) {
    return <div>Loading...</div>;
  }

  const isSingleImage = data.length === 1;
  const isFirstImage = currentImageIndex === 0;
  const isLastImage = currentImageIndex === data.length - 1;

  return (
    <div className="ProductDetailpage">
      <Header /> 
      
      <div className="product-detail-wrapper">
        <h2 className="product-detail-title">Product Details:</h2>
    
        {/* Carousel */}
        <div className="carousel">
          {!isFirstImage ? (
            <img 
              src={prevIcon} 
              className={`carousel-icon prev-icon`} 
              onClick={prevImage} 
              alt="Previous" 
            />
          ) : (
            <img src={prevIcon} className="carousel-icon disabled" alt="Previous" />
          )}
          <div className="product-info">
            <img src={productDetail.image} alt={productDetail.description} className="carousel-image" />
            <p className="product-description-class">{productDetail.description}</p>
          </div>
          {!isLastImage ? (
            <img 
              src={nextIcon} 
              className={`carousel-icon next-icon`} 
              onClick={nextImage} 
              alt="Next" 
            />
          ) : (
            <img src={nextIcon} className="carousel-icon disabled" alt="Next" />
          )}
        </div>
    
        <div className='product-detail-section'>
          <div className="about-product-info">
            <div className="about-product-content">
              <div className="about-product-label">About Product:</div>
            </div>
          </div>
          <div className="product-detail-info">
    <div className="product-detail-text">{productDetail.info}</div>
    <a href="/contact-us" className="enquiry-button">Enquiry</a>
</div>

        </div>
      </div>

      <Footer /> 
    </div>
  );
};

export default BusDuctProductDetail;