import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import hts_logo from '../assests/hts-logo.png';
import './Header.css';

function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isMenuOpen, setMenuOpen] = useState(false); // State for menu open/close
  const dropdownRef = useRef(null);
  const isTouchDevice = 'ontouchstart' in document.documentElement;

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const closeDropdown = () => {
    setActiveDropdown(null);
  };

  const handleDropdownToggle = (dropdownName) => {
    setActiveDropdown((prevDropdown) => (prevDropdown === dropdownName ? null : dropdownName));
  };

  const handleNavLinkClick = (path) => {
    navigate(path);
    closeDropdown();
    closeMenu(); // Close menu when a nav link is clicked
  };

  const isActive = (path) => {
    return location.pathname === path ? 'active' : '';
  };

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleDropdownHover = (dropdownName) => {
    if (!isTouchDevice) {
      setActiveDropdown(dropdownName);
    }
  };

  const handleDropdownClick = (dropdownName) => {
    if (isTouchDevice) {
      setActiveDropdown((prevDropdown) => (prevDropdown === dropdownName ? null : dropdownName));
    }
  };

  return (
    <Navbar expand="lg" className="navbar-full-width fixed-top p-0">
      <Container className="navbar-container">
        <Navbar.Brand className="navbar-image-logo p-0" onClick={() => handleNavLinkClick('/')}>
          <img src={hts_logo} alt="" width={114} height={80} />
        </Navbar.Brand>
        
        <Navbar.Toggle 
          aria-controls="basic-navbar-nav" 
          className="navbar-toggler"
          onClick={toggleMenu} // Toggle menu on click
        >
          {isMenuOpen ? <FontAwesomeIcon icon={faTimes} /> : <FontAwesomeIcon icon={faBars} />}
        </Navbar.Toggle>

        <Navbar.Collapse id="basic-navbar-nav" className={isMenuOpen ? 'show' : ''}>
          <Nav className="me-auto"></Nav>
          <Nav className="ml-auto" ref={dropdownRef}>
            <NavLink 
              className={`nav-link mx-3 ${isActive('/')}`} 
              to="/" 
              onClick={() => handleNavLinkClick('/')}
            >
              Home
            </NavLink>

            <NavDropdown
              title="About Us "
              id="dropdown-who-we-are"
              show={activeDropdown === 'WhoWeAre'}
              onMouseEnter={() => handleDropdownHover('WhoWeAre')}
              onMouseLeave={() => handleDropdownHover(null)}
              onClick={() => handleDropdownClick('WhoWeAre')}
              className={`custom-dropdown mx-3 ${isActive('/about-us') || isActive('/why-hts') || isActive('/who-we-are') || isActive('/r-and-d-approvals') ? 'active' : ''}`}
            >
              <NavDropdown.Item 
                className={isActive('/about-us')} 
                onClick={() => handleNavLinkClick('/about-us')}
              >
                About
              </NavDropdown.Item>
              <NavDropdown.Item 
                className={isActive('/why-hts')} 
                onClick={() => handleNavLinkClick('/why-hts')}
              >
                Why HTS
              </NavDropdown.Item>
              <NavDropdown.Item 
                className={isActive('/who-we-are')} 
                onClick={() => handleNavLinkClick('/who-we-are')}
              >
                Who We Are
              </NavDropdown.Item>
              <NavDropdown.Item 
                className={isActive('/r-and-d-approvals')} 
                onClick={() => handleNavLinkClick('/r-and-d-approvals')}
              >
                R&D Approvals
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown
              title="Products"
              id="dropdown-products"
              show={activeDropdown === 'Products'}
              onMouseEnter={() => handleDropdownHover('Products')}
              onMouseLeave={() => handleDropdownHover(null)}
              onClick={() => handleDropdownClick('Products')}
              className={`custom-dropdown mx-3 ${isActive('/products') || isActive('/mv-solutions') || isActive('/lv-solutions') || isActive('/bus-duct') || isActive('/retrofitting') || isActive('/Spare-Services') ? 'active' : ''}`}

            >
              <NavDropdown.Item 
                className={isActive('/mv-solutions')} 
                onClick={() => handleNavLinkClick('/mv-solutions')}
              >
                MV Solution
              </NavDropdown.Item>
              <NavDropdown.Item 
                className={isActive('/lv-solutions')} 
                onClick={() => handleNavLinkClick('/lv-solutions')}
              >
                LV Solution
              </NavDropdown.Item>
              <NavDropdown.Item 
                className={isActive('/bus-duct')} 
                onClick={() => handleNavLinkClick('/bus-duct')}
              >
                Bus Duct
              </NavDropdown.Item>
              <NavDropdown.Item 
                className={isActive('/retrofitting')} 
                onClick={() => handleNavLinkClick('/retrofitting')}
              >
                Retrofitting
              </NavDropdown.Item>
              <NavDropdown.Item 
                className={isActive('/Spare-Services')} 
                onClick={() => handleNavLinkClick('/Spare-Services')}
              >
               Spare & Services
              </NavDropdown.Item>
            </NavDropdown>

            <NavLink 
              className={`nav-link mx-3 ${isActive('/awards')}`} 
              to="/awards" 
              onClick={() => handleNavLinkClick('/awards')}
            >
              Awards
            </NavLink>

            <NavLink 
              className={`nav-link mx-3 ${isActive('/clients')}`} 
              to="/our-clients" 
              onClick={() => handleNavLinkClick('/clients')}
            >
              Clients
            </NavLink>

            <NavLink 
              className={`nav-link mx-3 ${isActive('/contact-us')}`} 
              to="/contact-us" 
              onClick={() => handleNavLinkClick('/contact-us')}
            >
              Contact Us
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
