// Home.js

import React from 'react';
import Header from '../Components/Header/Header.js';

import Footer from '../Components/Footer/Footer.js'
import ResearchDev from '../Components/WhoWeAre/ResearchDev/ResearchDev.js';
import Rd2comp from '../Components/WhoWeAre/ResearchDev/rd2comp.js';


function ReasechDevPage() {
  return (
    <div className="">
      <Header/> 
     <ResearchDev/>
     {/* <Rd2comp/> */}
     <Footer/>
    </div>
  );
}

export default ReasechDevPage;
