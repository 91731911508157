// Home.js

import React from 'react';
import Header from '../Components/Header/Header';

import Footer from '../Components/Footer/Footer.js'
import WhyHtsPage from '../Components/WhoWeAre/WhyHts/WhyHtsPage.js';

function WhyHtsMainPage() {
  return (
    <div className="Home">
      <Header/> 
     <WhyHtsPage/>
     <Footer/>
    </div>
  );
}

export default WhyHtsMainPage;
