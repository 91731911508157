import React, { useEffect } from 'react';
import './About2comp.css';
import sectionImage from '../w-assests/managment.svg'; 
import { profiles } from './profileData'; // Update with your data file
import AOS from 'aos';
import 'aos/dist/aos.css';

const About2comp = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, 
      easing: 'ease-in-out', 
      once: false, 
    });
  }, []);
  return (
    <div className="whowe2-container" data-aos="fade-up" data-aos-duration="200">
      <div className="section-image">
        <img src={sectionImage} alt="Section" data-aos="fade-up" />
        <div className="section-content">
          <div className="section-para">
            A promising Switchgear Manufacturing and Service provider company was established by a group of expert professionals. Diverse experience came together for achieving a common goal to steer customers through the next generation of business innovation powered by technology. The company was founded on the principle of building and implementing great ideas that drive progress for clients and enhance lives through enterprise solutions.
          </div>
        </div>
      </div>

      {profiles.map((profile, index) => (
        <div className="testimonial-content" key={index} data-aos="fade-up" data-aos-duration="400">
          <div className="management-section">
            <div className="management-image ">
              <img className='zoomer-mover' src={profile.image} alt={profile.name} />
            </div>
            <div className="management-content">
              <h3 className="management-name">{profile.name}</h3>
              <p className="management-title">{profile.title}</p>
              <hr className="divider-line" />
              <p className="management-para" data-aos="fade-up" data-aos-delay="200">{profile.description}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default About2comp;
