import React, { useEffect, useRef } from 'react';
import './WhyHtsPage.css';
import { points } from './PointFile';
import htsImage from '../w-assests/why-hts.webp';
import checkIcon from '../w-assests/Group.svg'; 
import AOS from 'aos';
import 'aos/dist/aos.css';

const WhyHtsPage = () => {
  const pointsRef = useRef([]);

  useEffect(() => {
    AOS.init({ once: true }); // Initialize AOS once
  }, []);

  return (
    <div className="why-hts-wrapper ">
      <div className="why-hts-image" data-aos="fade-right" data-aos-duration="2000">
        <img src={htsImage} alt="HTS" />
      </div>
      <div className="why-hts-content">
        <div className='why-hts-blog' data-aos="fade-left" data-aos-duration="2000">
          <p className="why-hts-para">
            HTS provides a wide selection of finest quality panels for application in various kinds of electrical appliances. Our exceptional services specialize in providing timely, high-quality items at an appropriate cost. Among our unique specialties are:
          </p>
        </div>
        <div className="why-hts-points">
          {points.map((point, index) => (
            <div key={index} className="why-hts-point" ref={(el) => (pointsRef.current[index] = el)} data-aos="fade-up">
              <img src={checkIcon} alt="check" className="check-icon" />
              <div className="why-hts-point-text">{point.text}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhyHtsPage;
