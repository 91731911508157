import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Award1Comp.css';

// Import all images
import awardBg from './award-assests/award-banner.webp';
import awardLeft from './award-assests/img-2.webp';
import awardRightTop from './award-assests/img-3.webp';
import awardRightBottom from './award-assests/img-4.webp';
import galleryBg from './award-assests/img-5.1.webp';
import galleryImage1 from './award-assests/gallary/1.webp';
import galleryImage2 from './award-assests/gallary/2.webp';
import galleryImage3 from './award-assests/gallary/3.webp';
import galleryImage4 from './award-assests/gallary/4.webp';
import galleryImage5 from './award-assests/gallary/5.webp';
import galleryImage6 from './award-assests/gallary/6.webp';
import galleryImage7 from './award-assests/gallary/7.webp';
import galleryImage8 from './award-assests/gallary/8.webp';
import galleryImage9 from './award-assests/gallary/9.webp';
import galleryImage10 from './award-assests/gallary/10.webp';
import galleryImage11 from './award-assests/gallary/11.webp';
import galleryImage12 from './award-assests/gallary/12.webp';

import bottomImage from './award-assests/bottom-banner.webp';

const Award1Comp = () => {

  useEffect(() => {
    AOS.init({
      duration: 1000, 
      easing: 'ease-in-out', 
      once: false, 
      disable: window.innerWidth <= 1000, // Disable AOS on screens smaller than or equal to 768px
    });
  }, []);

  return (
    <div>
      <div className="award-container" >
        <img className="full-width" src={awardBg} alt="Award background" data-aos="fade-right"  data-aos-delay="200" />

        <div className="awards-content">
          <h2 className="award-title" data-aos="fade-right"  data-aos-delay="400">Awards:</h2>

          <div className="award-section" >
            <div className="award-left zoomer-mover" data-aos="fade-right"  data-aos-easing="ease-in-sine"  data-aos-delay="600" >
              <img src={awardLeft} alt="Award left" className="center-image" />
            </div>

            <div className="award-right" data-aos="fade-left">
              <img src={awardRightTop} alt="Award right top" className="center-image zoomer-mover" data-aos="fade-down"   data-aos-delay="800"/>
              <img src={awardRightBottom} alt="Award right bottom" className="center-image zoomer-mover" data-aos="fade-down"  data-aos-delay="1000" />
            </div>
          </div>
        </div>
      </div>


      <div className="gallery-container" data-aos="fade-up">
        <h2 className="title red" data-aos="fade-right" data-aos-delay="1200">Gallery:</h2>
        <img className="full-width-2" src={galleryBg} alt="Gallery background" data-aos="fade-up" />

        <div className="grid-container" data-aos="fade-up">
          <div className="grid-box" data-aos="fade-right" data-aos-delay="1400">
            <img src={galleryImage1} alt="Gallery Image 1" className="grid-image zoomer-mover" />
          </div>
          <div className="grid-box" data-aos="fade-left" data-aos-delay="1600">
            <img src={galleryImage2} alt="Gallery Image 2" className="grid-image zoomer-mover" />
          </div>
          <div className="grid-box" data-aos="fade-right" data-aos-delay="1800">
            <img src={galleryImage3} alt="Gallery Image 3" className="grid-image zoomer-mover" />
          </div>
          <div className="grid-box" data-aos="fade-left" data-aos-delay="2000">
            <img src={galleryImage4} alt="Gallery Image 4" className="grid-image zoomer-mover" />
          </div>
          <div className="grid-box" data-aos="fade-right" data-aos-delay="2200">
            <img src={galleryImage5} alt="Gallery Image 5" className="grid-image zoomer-mover" />
          </div>
          <div className="grid-box" data-aos="fade-left" data-aos-delay="2400">
            <img src={galleryImage6} alt="Gallery Image 6" className="grid-image zoomer-mover" />
          </div>
          <div className="grid-box" data-aos="fade-right" data-aos-delay="3200">
            <img src={galleryImage7} alt="Gallery Image 7" className="grid-image zoomer-mover" />
          </div>
          <div className="grid-box" data-aos="fade-left" data-aos-delay="3400">
            <img src={galleryImage8} alt="Gallery Image 8" className="grid-image zoomer-mover" />
          </div>
          <div className="grid-box" data-aos="fade-right" data-aos-delay="3600">
            <img src={galleryImage9} alt="Gallery Image 9" className="grid-image zoomer-mover" />
          </div>
          <div className="grid-box" data-aos="fade-left" data-aos-delay="3200">
            <img src={galleryImage10} alt="Gallery Image 10" className="grid-image zoomer-mover" />
          </div>
          <div className="grid-box" data-aos="fade-right" data-aos-delay="3400">
            <img src={galleryImage11} alt="Gallery Image 11" className="grid-image zoomer-mover" />
          </div>
          <div className="grid-box" data-aos="fade-left" data-aos-delay="3600">
            <img src={galleryImage12} alt="Gallery Image 12" className="grid-image zoomer-mover" />
          </div>
        </div>
      </div>

      <div className="bottom-container" data-aos="fade-up" >
        <img className="full-width" src={bottomImage} alt="Bottom image" data-aos-delay="3800" />
      </div>
    </div>
  );
};

export default Award1Comp;
